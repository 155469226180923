import { DrupalClient } from "next-drupal"

export const drupal = new DrupalClient(
  process.env.NEXT_INTERNAL_DRUPAL_API_URL,
  {
    debug: process.env.NODE_ENV !== 'production',
    throwJsonApiErrors: process.env.NODE_ENV !== 'production',
    frontPage: '/',
  }
)
