import * as React from "react"
import { DrupalMenuLinkContent } from "next-drupal"
import { Navbar } from "components/layout/navbar"
import { NavbarHomepage } from "./navbarhomepage";
import { Footer } from "components/layout/footer"
import { Suspense } from "react";
import Loading from "../../pages/Loading";
import { useRouter } from "next/router";

export interface LayoutProps {
    menus: {
        main: DrupalMenuLinkContent[]
        footer: DrupalMenuLinkContent[],
        social: DrupalMenuLinkContent[]
    }
    children?: React.ReactNode
}

export function Layout({ menus, children }: LayoutProps) {
    const router = useRouter();
    if( router.pathname == '/'){
        return (
            <>
                <NavbarHomepage links={menus.main} pathName={router.pathname}/>
                <main className="homepage--main">
                    <Suspense fallback={<Loading />}>{children}</Suspense>
                </main>
                <Footer isHomepage={true} footer={menus.footer} social={menus.social} />
            </>
        )
    } else {
        return (
            <>
                <Navbar links={menus.main} pathName={router.pathname}/>
                <main>
                    <Suspense fallback={<Loading />}>{children}</Suspense>
                </main>
                <Footer footer={menus.footer} social={menus.social} />
            </>
        )
    }
}
