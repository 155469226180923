import Head from "next/head"
import { useRouter } from "next/router"
import Script from "next/script";
import config from "site.config";

export interface DrupalMetatag {
    tag: string
    attributes: {
        content: string
        name?: string
        rel?: string
        href?: string
        src?: string
    },
    tag_content?: string
}
interface MetaProps {
    title?: string
    path?: string
    gtag_code?: string
    tags?: DrupalMetatag[]
    scripts?: DrupalMetatag[]
}

export function Meta({ title, gtag_code, tags, scripts}: MetaProps) {
    const router = useRouter()

    let gtag = config.GTAG_CODE.default;
    if( gtag_code && config.GTAG_CODE.hasOwnProperty(gtag_code) ) {
        gtag = config.GTAG_CODE[gtag_code];
    }

    return (
        <>
        <Head>
            <link
                rel="canonical"
                href={`${process.env.NEXT_PUBLIC_DRUPAL_API_URL}${
                    router.asPath !== "/" ? router.asPath : ""
                }`}
            />

            {tags?.length ? (
                tags.map((tag, index) => {
                    if (tag.attributes?.rel === "canonical") {
                        return null
                    }

                    if (tag.attributes?.name === "title") {
                        return (
                            <title key={tag.attributes.name}>{tag.attributes.content}</title>
                        )
                    }
                    const Tag = tag.tag as keyof JSX.IntrinsicElements
                    if( tag?.tag_content){
                        return <Tag key={index} {...tag.attributes}>{tag.tag_content}</Tag>
                    }
                    return <Tag key={index} {...tag.attributes}></Tag>
                })
            ) : (
                <>
                    <title>{title}</title>
                    <meta
                        name="description"
                        content="A Next.js blog powered by a Drupal backend."
                    />
                    <meta
                        property="og:image"
                        content={`${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/images/meta.jpg`}
                    />
                    <meta property="og:image:width" content="800" />
                    <meta property="og:image:height" content="600" />
                </>
            )}
        </Head>
            { gtag ?
                <>
            <Script id="gtag_async" key="gtag_async" async src={"https://www.googletagmanager.com/gtag/js?id="+gtag}></Script>
            <Script id="google-analytics" key="google-analytics">
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag}');
                `}
            </Script>
                    </>
                :
                null }
            {scripts?.length ? (
                scripts.map((tag, index) => {
                    if( tag.tag == 'Script'){
                        if( tag?.tag_content) {
                            return (<Script
                                id={"script_" + index}
                                key={"script_" + index}
                                dangerouslySetInnerHTML={{
                                    __html: tag.tag_content,
                                }}
                            />)
                        } else {
                            return <Script id={"script_" + index} key={"script_" + index} {...tag.attributes} strategy="beforeInteractive" />
                        }

                    }

                })
            ) :
                null
            }
        </>
    )
}
