export function formatDate(input: string): string {
  let date = new Date(input)
  let yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();
  let day = ''+dd;
  let month = ''+mm;
  if (dd < 10) { day = '0' + dd;}
  if (mm < 10) { month = '0' + mm;}

  let formattedToday = day + '/' + month + '/' + yyyy;
  return formattedToday;
}

export function absoluteUrl(input: string) {
  if( input.indexOf('/') == 0 ){
    return `${process.env.NEXT_IMAGE_URL}${input}`
  }
  return `${process.env.NEXT_IMAGE_URL}/${input}`
}

export function processHTML(html: string) {
  return html.split(`src="/`).join(`src="${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/`);
}

export function filterTagsFromCampaigns(input) {
  const tags_lists = input.map((project) => {
    return project.field_tags;
  });

  let return_tags = [];
  tags_lists.map((tag_list)=>{
    tag_list.map((tag)=>{
      return_tags.push({id:tag.id, name: tag.name});
    })
  })
  const tags = Array.from(new Set(return_tags.map(s => s.id)))
      .map(id => {
        return {
          id: id,
          name: return_tags.find(s => s.id == id).name
        };
      });

  return tags;
}

export function isRelative(url: string) {
  return !new RegExp("^(?:[a-z]+:)?//", "i").test(url)
}
